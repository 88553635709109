import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Alert,
} from "@mui/material";
import loginHero from "../assets/login.jpg";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import axios from "axios";

export default function LoginPage({ darkMode, toggleDarkMode }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    try {
      const response = await axios.post(
        "https://enfogen-backend-p69g.onrender.com/api/auth/signin",
        { username: username.toLowerCase(), password }
      );
      setLoading(false);
      localStorage.setItem("jwt_token", response.data.accessToken);
      localStorage.setItem("user_name", response.data.username);
      localStorage.setItem("user_email", response.data.email);
      localStorage.setItem("isFreeUser", response.data.waitlisted);
      console.log(
        "response.data.subscriptionCancellationTime",
        response.data.subscriptionCancellationTime
      );
      if (response.data.subscriptionCancellationTime !== null) {
        localStorage.setItem(
          "cancellation_date",
          response.data.subscriptionCancellationTime
        );
      }
      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);
      setLoading(false);
      setError(
        "Login failed. Please check your username and password and try again."
      );
    }
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    try {
      const response = await axios.post(
        "https://enfogen-backend-p69g.onrender.com/api/auth/verify-email",
        { email: email.toLowerCase() }
      );
      if (response.data === false) {
        setLoading(false);
        setError(
          "Email is not registered, Please use a registered email Or Sign Up."
        );
      } else {
        await axios.post(
          "https://enfogen-backend-p69g.onrender.com/api/auth/forgot-password",
          { email: email.toLowerCase() }
        );
        setLoading(false);
        setSuccess("A reset link was sent to your email.");
      }
    } catch (error) {
      console.error("Password reset failed:", error);
      setLoading(false);
      setError("Password reset failed. Please try again later.");
    }
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const lightTheme = createTheme();
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div style={{ display: "flex", alignItems: "center", minHeight: "82vh" }}>
        <div
          style={{ flex: 1, padding: "20px", width: "100%", maxWidth: "600px" }}
        >
          <h1 style={{ marginTop: "40px", marginBottom: "20px" }}>
            Unlock Your Career Potential with Us
          </h1>
          {showForgotPassword ? (
            <form onSubmit={handleForgotPassword}>
              {error && <Alert severity="error">{error}</Alert>}
              {success && <Alert severity="success">{success}</Alert>}
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Send Reset Link"
                )}
              </Button>
              <Button
                color="primary"
                fullWidth
                onClick={() => setShowForgotPassword(false)}
                style={{ marginTop: "20px" }}
              >
                Back to Login
              </Button>
            </form>
          ) : (
            <form onSubmit={handleLogin}>
              {error && <Alert severity="error">{error}</Alert>}
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                value={username}
                helperText="Username is not your email address"
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                type={showPassword ? "text" : "password"}
                label="Password"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword} edge="end">
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}
              </Button>
              <Button
                color="primary"
                fullWidth
                onClick={() => setShowForgotPassword(true)}
                style={{ marginTop: "10px" }}
              >
                Forgot Password?
              </Button>
              <p style={{ marginTop: "20px" }}>
                Don't have an account?{" "}
                <Button color="primary" onClick={handleSignUp}>
                  Sign Up
                </Button>
              </p>
            </form>
          )}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src={loginHero}
            alt=""
            style={{ width: "100%", maxWidth: "400px", height: "auto" }}
          />
        </div>
      </div>
      <style>
        {`
          @media (max-width: 600px) {
            div > div {
              flex-direction: column;
              align-items: center;
              padding: 0 0px;
            }
          }
        `}
      </style>
    </ThemeProvider>
  );
}
