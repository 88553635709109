import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Alert, // Import Alert component for error messages
} from "@mui/material";
import signupHero from "../assets/signup.jpg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import LoginModal from "../components/LoginModal";

export default function SignupPage({ darkMode, toggleDarkMode }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate hook
  const message = "Please login now with your newly registered username";

  function isValidPassword(password) {
    // Criteria flags
    let hasUpperCase = false;
    let hasLowerCase = false;
    let hasDigit = false;
    let hasSpecialChar = false;

    // Check length
    if (password.length < 8) {
      return false;
    }

    // Iterate through each character in the password
    for (let i = 0; i < password.length; i++) {
      const char = password[i];

      if (char >= "A" && char <= "Z") {
        hasUpperCase = true;
      } else if (char >= "a" && char <= "z") {
        hasLowerCase = true;
      } else if (char >= "0" && char <= "9") {
        hasDigit = true;
      } else {
        // If the character is neither a digit nor an alphabet, it's a special character
        hasSpecialChar = true;
      }

      // If all criteria are met, no need to continue
      if (hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar) {
        return true;
      }
    }

    // Check if all criteria are met
    return hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar;
  }

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    if (!isValidPassword(password)) {
      setPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long."
      );
      return;
    }
    try {
      const roles = [];
      setLoading(true);
      await axios.post(
        "https://enfogen-backend-p69g.onrender.com/api/auth/signup",
        { username: username.toLowerCase(), email, password, roles }
      );
      setOpenModal(true);
      setLoading(false);
    } catch (error) {
      console.error("Signup failed:", error);
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = error.response.data.message;
        if (errorMessage.includes("Username is already taken")) {
          setUsernameError(errorMessage);
        } else if (errorMessage.includes("Email is already in use")) {
          setEmailError(errorMessage);
        }
      }
    }
  };

  const clearUsernameError = () => {
    setUsernameError("");
  };

  const clearEmailError = () => {
    setEmailError("");
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    navigate("/login"); // Navigate to login page
  };

  const lightTheme = createTheme();
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div style={{ display: "flex", alignItems: "center", minHeight: "82vh" }}>
        {/* Signup form */}
        <div
          style={{ flex: 1, padding: "20px", width: "100%", maxWidth: "600px" }}
        >
          <h1 style={{ marginTop: "40px", marginBottom: "20px" }}>
            Jump Aboard Today!
          </h1>
          <form onSubmit={handleSignup}>
            {usernameError && <Alert severity="error">{usernameError}</Alert>}
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              required
              onChange={(e) => {
                setUsername(e.target.value);
                clearUsernameError(); // Clear username error when typing
              }}
              error={usernameError !== ""}
            />
            {username.length > 20 && (
              <Alert severity="error">
                Username cannot exceed 20 characters
              </Alert>
            )}
            {emailError && <Alert severity="error">{emailError}</Alert>}
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              required
              error={emailError !== ""}
              onChange={(e) => {
                setEmail(e.target.value);
                clearEmailError(); // Clear email error when typing
              }}
            />
            {email.length > 50 && (
              <Alert severity="error">Email cannot exceed 50 characters</Alert>
            )}
            {passwordError && <Alert severity="error">{passwordError}</Alert>}
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowPassword}
                      className="toggle-password-visibility"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {password.length > 120 && (
              <Alert severity="error">
                Password cannot exceed 120 characters
              </Alert>
            )}
            <TextField
              label="Confirm Password"
              type={showPassword ? "text" : "password"} // Use the same show/hide state as the password field
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              required
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setPasswordError("");
              }}
              error={passwordError !== ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowPassword}
                      className="toggle-password-visibility" // Added className
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={!username || !email || !password || !confirmPassword}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "AGREE & JOIN"
              )}
            </Button>
          </form>
          <p style={{ marginTop: "20px" }}>
            Already have an account?{" "}
            <Button color="primary" onClick={handleLogin}>
              Login
            </Button>
          </p>
        </div>
        {/* Hero image */}
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src={signupHero}
            alt=""
            style={{ width: "100%", maxWidth: "400px", height: "auto" }}
          />
        </div>
      </div>
      {/* Media query for phones */}
      <style>
        {`
            @media (max-width: 600px) {
              div > div {
                flex-direction: column;
                align-items: center;
              }
              .toggle-password-visibility {
                display: none;
              }
                padding: 0 20px;
            }
          `}
      </style>
      <LoginModal
        darkMode={darkMode}
        open={openModal}
        message={message}
        handleClose={() => setOpenModal(false)}
      />
    </ThemeProvider>
  );
}
