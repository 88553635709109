import React, { useState } from "react";
import {
  ThemeProvider,
  createTheme,
  Button,
  TextField,
  Grid,
  Box,
  CssBaseline,
  Typography,
} from "@mui/material";
import axios from "axios";

export default function ContactUsPage({ darkMode, toggleDarkMode }) {
  const [verification, setVerification] = useState("");
  const [verificationError, setVerificationError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");

  const lightTheme = createTheme();
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleVerificationChange = (e) => {
    setVerification(e.target.value);
    if (parseInt(e.target.value) !== 7) {
      setVerificationError(true);
    } else {
      setVerificationError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parseInt(verification) !== 7) {
      setVerificationError(true);
      return;
    }

    axios
      .post(
        "https://enfogen-backend-p69g.onrender.com/api/mail/contact-mail", // Replace with your API endpoint
        formData
      )
      .then(
        (response) => {
          setFormSuccess("Message sent successfully!");
          setFormError("");
          setFormData({
            name: "",
            email: "",
            message: "",
          });
          setVerification("");
        },
        (err) => {
          setFormError("Failed to send message, please try again.");
          setFormSuccess("");
        }
      );
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Box
        sx={{
          background: darkMode ? "#333" : "#fff",
          color: darkMode ? "#fff" : "#333",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="h1"
                sx={{
                  fontSize: "3rem",
                  mb: 3,
                  fontFamily: "Poppins, sans-serif",
                  color: darkMode ? "white" : "#333",
                }}
              >
                We are your go-to experts for job searches and career
                development.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.25rem",
                  mb: 2,
                  fontFamily: "Arial, sans-serif",
                  color: darkMode ? "white" : "#333",
                }}
              >
                Specialists dedicated to helping professionals find the perfect
                job opportunities to match their skills and aspirations. Our
                platform caters to a wide range of industries and positions,
                providing personalized job recommendations and career resources.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.25rem",
                  fontFamily: "Arial, sans-serif",
                  color: darkMode ? "white" : "#333",
                }}
              >
                For more information about how we can help support your job
                search journey, please complete the form below, or email
                <a href="mailto:support@enfogenconsulting.com">
                  {" "}
                  support@enfogenconsulting.com
                </a>
                .
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                padding: 3,
                border: "1px solid",
                borderColor: darkMode ? "#444" : "#ccc",
                borderRadius: 2,
                backgroundColor: darkMode ? "#444" : "#f9f9f9",
              }}
            >
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Name"
                  name="name"
                  variant="outlined"
                  fullWidth
                  required
                  margin="normal"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  required
                  margin="normal"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  required
                  margin="normal"
                  value={formData.message}
                  onChange={handleInputChange}
                />
                <TextField
                  label="What is 3 + 4?"
                  variant="outlined"
                  fullWidth
                  required
                  margin="normal"
                  value={verification}
                  onChange={handleVerificationChange}
                  error={verificationError}
                  helperText={
                    verificationError
                      ? "Incorrect answer, please try again."
                      : ""
                  }
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Submit
                </Button>
                {formError && (
                  <Typography color="error" sx={{ mt: 2 }}>
                    {formError}
                  </Typography>
                )}
                {formSuccess && (
                  <Typography color="success" sx={{ mt: 2 }}>
                    {formSuccess}
                  </Typography>
                )}
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
