import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import {
  ThemeProvider,
  createTheme,
  TextField,
  Button,
  CircularProgress,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";

export default function PasswordResetPage({ darkMode }) {
  const { email } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const decryptEmail = (encryptedEmail) => {
    try {
      // Convert the URL-safe Base64 string to a standard Base64 string
      const base64 = encryptedEmail.replace(/-/g, "+").replace(/_/g, "/");

      // Decode the Base64 string
      const encryptedBytes = CryptoJS.enc.Base64.parse(base64);

      // Define your secret key
      const secretKey = "your-one-keyvalu";

      // Parse the secret key
      const key = CryptoJS.enc.Utf8.parse(secretKey);

      // Decrypt the bytes
      const decryptedBytes = CryptoJS.AES.decrypt(
        { ciphertext: encryptedBytes },
        key,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // Convert the decrypted bytes to a UTF-8 string
      const decryptedEmail = decryptedBytes.toString(CryptoJS.enc.Utf8);

      return decryptedEmail;
    } catch (e) {
      console.error("Decryption failed:", e);
      return null;
    }
  };

  const decryptedEmail = decryptEmail(email);

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setError("");
    setSuccess("");
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (!isValidPassword(newPassword)) {
      setError(
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long."
      );
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        "https://enfogen-backend-p69g.onrender.com/api/auth/reset-password",
        {
          email: decryptedEmail,
          password: newPassword,
        }
      );
      setLoading(false);
      setSuccess("Password reset successfully. You can now log in.");
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (error) {
      console.error("Password reset failed:", error);
      setLoading(false);
      setError("Password reset failed. Please try again.");
    }
  };

  const isValidPassword = (password) => {
    let hasUpperCase = false;
    let hasLowerCase = false;
    let hasDigit = false;
    let hasSpecialChar = false;

    if (password.length < 8) {
      return false;
    }

    for (let i = 0; i < password.length; i++) {
      const char = password[i];

      if (char >= "A" && char <= "Z") {
        hasUpperCase = true;
      } else if (char >= "a" && char <= "z") {
        hasLowerCase = true;
      } else if (char >= "0" && char <= "9") {
        hasDigit = true;
      } else {
        hasSpecialChar = true;
      }

      if (hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar) {
        return true;
      }
    }

    return hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar;
  };

  const lightTheme = createTheme();
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "82vh",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "100%", maxWidth: "400px", padding: "20px" }}>
          <h1>Reset Your Password</h1>
          <form onSubmit={handlePasswordReset}>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={decryptedEmail}
              disabled
            />
            <TextField
              type={showPassword ? "text" : "password"}
              label="New Password"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      className="toggle-password-visibility"
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showPassword ? "text" : "password"}
              label="Confirm Password"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      className="toggle-password-visibility"
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={loading}
              style={{ marginTop: "20px" }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Reset Password"
              )}
            </Button>
          </form>
        </div>
      </div>
      <style>
        {`
          @media (max-width: 600px) {
            div > div {
              flex-direction: column;
              align-items: center;
            }
            .toggle-password-visibility {
                display: none;
              }
          }
        `}
      </style>
    </ThemeProvider>
  );
}
