import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import loginHero from "../assets/login.jpg";
import axios from "axios";

export default function DeleteAccountPage({ darkMode, toggleDarkMode }) {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    setLoading(true);
    setError("");

    try {
      // Get the stored username from localStorage
      const storedUsername = localStorage.getItem("user_name");

      // Check if entered username matches the stored username
      if (username.toLowerCase() !== storedUsername.toLowerCase()) {
        setLoading(false);
        setError("Username is incorrect. Please enter the correct username.");
        return;
      }

      // Call the API to delete the account
      await axios.delete(
        "https://enfogen-backend-p69g.onrender.com/api/auth/delete/" +
          username.toLowerCase()
      );

      // Clear localStorage and redirect to the login page or a success page
      localStorage.clear();
      setLoading(false);
      setSuccess("Your account has been deleted successfully.");
      navigate("/"); // Redirect to the login page or home page after account deletion
    } catch (error) {
      console.error("Account deletion failed:", error);
      setLoading(false);
      setError("Account deletion failed. Please try again later.");
    }
  };

  const openConfirmationDialog = (event) => {
    event.preventDefault();
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  const lightTheme = createTheme();
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div style={{ display: "flex", alignItems: "center", minHeight: "82vh" }}>
        <div
          style={{ flex: 1, padding: "20px", width: "100%", maxWidth: "600px" }}
        >
          <h1 style={{ marginTop: "40px", marginBottom: "20px" }}>
            Delete Your Account
          </h1>
          <form onSubmit={openConfirmationDialog}>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
            <TextField
              label="Confirm Username"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Button variant="contained" color="primary" fullWidth type="submit">
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Delete Account"
              )}
            </Button>
          </form>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src={loginHero}
            alt=""
            style={{ width: "100%", maxWidth: "400px", height: "auto" }}
          />
        </div>
      </div>

      <Dialog open={showConfirmationDialog} onClose={closeConfirmationDialog}>
        <DialogTitle>Confirm Account Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              closeConfirmationDialog();
              handleDeleteAccount();
            }}
            color="secondary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <style>
        {`
          @media (max-width: 600px) {
            div > div {
              flex-direction: column;
              align-items: center;
              padding: 0 20px;
            }
          }
        `}
      </style>
    </ThemeProvider>
  );
}
