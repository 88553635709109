import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const CancelSubscriptionPage = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleCancelSubscription = async () => {
    setLoading(true);
    setMessage("");
    setOpen(false);
    try {
      const response = await axios.post(
        "https://enfogen-backend-p69g.onrender.com/api/pay/cancel-subscription",
        {
          email: localStorage.getItem("user_email"),
          name: localStorage.getItem("user_name"),
        }
      );
      localStorage.setItem("isFreeUser", true);
      console.log(response);
      setMessage("Your subscription will be cancelled 30 days from now");
      // setMessage(response.data);
      localStorage.setItem("cancellation_date", response.data); // Add a timer to navigate after 2 seconds

      const timer = setTimeout(() => {
        navigate("/");
      }, 5000);

      // Clean up the timer to prevent memory leaks
      return () => clearTimeout(timer);
    } catch (error) {
      setMessage(error.response?.data || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container
      maxWidth="sm"
      style={{ minHeight: "73vh", textAlign: "center", marginTop: "50px" }}
    >
      <CancelIcon style={{ fontSize: "48px", color: "#888" }} />
      <Typography variant="h4" gutterBottom>
        Cancel Subscription
      </Typography>
      <Typography variant="body1" gutterBottom>
        Are you sure you want to cancel your subscription? Your subscription
        will be canceled immediately.
      </Typography>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={24} /> : <CancelIcon />}
          style={{ padding: "10px 20px" }}
        >
          {loading ? "Processing..." : "Cancel Subscription"}
        </Button>
      </Box>
      {message && (
        <Box mt={2}>
          <Alert severity={message.includes("success") ? "success" : "error"}>
            {message}
          </Alert>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel Subscription</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel your subscription? This action will
            take effect immediately.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No, Keep Subscription
          </Button>
          <Button
            onClick={handleCancelSubscription}
            color="secondary"
            autoFocus
          >
            Yes, Cancel Now
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CancelSubscriptionPage;
